module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-ca8b150fd1/3/buildhome/.yarn/berry/cache/gatsby-plugin-canonical-urls-npm-5.13.1-b01f445972-10c0.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.aiseservices.com"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-327263f461/3/buildhome/.yarn/berry/cache/gatsby-plugin-google-tagmanager-npm-5.13.1-f82e0c3055-10c0.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NTQ4N3J2","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"AISE","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-4a89a602ff/3/buildhome/.yarn/berry/cache/gatsby-plugin-catch-links-npm-5.13.1-d0715a6524-10c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-af38606c87/3/buildhome/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AISE","short_name":"AISE","description":"AISE","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"static/icons/android-chrome-192x192.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"e6b449869de4d235610663e80cafd8d0"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-nprogress-virtual-18ab6dcce4/3/buildhome/.yarn/berry/cache/gatsby-plugin-nprogress-npm-5.13.1-dbceab37cb-10c0.zip/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000000"},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-375807c76a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
